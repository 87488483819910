import React from "react"
import Layout from "../components/Layout"
import bioIvoryYoung from "../images/photo-board-ivory-young.jpg"

export default () => {
  return (
    <Layout>
      <main className="max-w-4xl flex-grow px-6 lg:px-0 md:mx-auto flex flex-col">        
        <div className="flex flex-col md:flex-row md:items-stretch md:space-x-16 mb-12">
          <div className="md:w-1/2">
            <h2 className="font-display font-bold text-6xl mb-4 text-cornflower-500">Ivory Young</h2>
            <div className="space-y-4 text-xl font-light leading-relaxed">
              <p>
                Ivory Young is a Houston native and a graduate of Houston Sterling High School and Alcorn State University.
              </p>
              <p>
                He played and worked under the tutelage of Basketball Hall of Fame coach Davey Whitney.
              </p>
              <p>
                Coach Young has over 30 years experience in coaching, with the last 28 years being at St. Vincent de Paul Catholic School.
              </p>
              <p>
                He is father of Ivory “Trey” Young, who played basketball at Strake Jesuit High School, Utah Valley University and overseas in Australia. Trey is currently a graduate assistant at Virginia Commonwealth University, VCU.
              </p>
            </div>
          </div>
          <div className="md:w-1/2 flex items-center justify-center">
            <img
                className=""
                src={bioIvoryYoung}
                alt="Ivory Young"
              />
          </div>
        </div>
      </main>
    </Layout>
  )
}
